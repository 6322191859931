import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'

import SingleLogBuildingCard from 'components/layout/LogBuilding/Card'

import type { LogBuildingCard } from 'components/layout/LogBuilding/Card'

type Props = {
  logs: LogBuildingCard[]
}

const Section = styled.section`
  padding: 0 0 4rem;
`

const LogBuildingSection: React.FC<Props> = ({ logs }) => {
  return (
    <Section>
      <Container>
        <Row alignItems="stretch" gap="1.25rem">
          {logs.map(({ image, name, title, excerpt, link, date, slug }) => (
            <Col xs={24} sm={12} lg={8} xxl={6} key={link}>
              <SingleLogBuildingCard
                image={image}
                name={name}
                title={title}
                excerpt={excerpt}
                link={link}
                date={date}
                slug={slug}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default LogBuildingSection
