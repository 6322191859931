import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { LocalizedLink } from 'gatsby-theme-i18n'

import { Text, WithLine } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

import formatDate from 'utils/formatDate'

import type Image from 'types/image'

import arrowRight from 'assets/icons/arrow-right-orange.svg'

export type LogBuildingCard = {
  image: Image
  name: string
  slug: string
  title: string
  excerpt: string
  link: string
  date: Date
}

const Wrapper = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid #e8e4dc;
  border-top: none;
  transition: 0.25s;
  flex: 1;
  ${({ theme }) => theme.media.xs.min} {
    padding: 2rem;
  }
  & > ${Text} {
    flex: 1;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 230px;
  overflow: hidden;
  ${({ theme }) => theme.media.xs.min} {
    height: 300px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.25s;
  opacity: 0;
  z-index: 2;
  background: ${({ theme }) => rgba(String(theme.colors.primary), 0.6)};
  display: flex;
  justify-content: center;
  align-items: center;
  & > ${Button} {
    pointer-events: none;
  }
`

const InnerWrapper = styled(LocalizedLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
  &:hover {
    & > ${Overlay} {
      opacity: 1;
    }
  }
`

const Tag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 40px;
  width: 160px;
  min-width: min-content;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;
`

const SingleLogBuildingCard: React.FC<LogBuildingCard> = ({
  image,
  name,
  excerpt,
  link,
  title,
  date,
}) => {
  return (
    <Wrapper>
      <InnerWrapper to={link}>
        <Overlay>
          <Button as="div" white round roundSize="100px">
            <Icon src={arrowRight} size={18} />
          </Button>
        </Overlay>
        <Tag>
          <Text size={14} weight={500} themecolor="white" transform="uppercase">
            {name}
          </Text>
        </Tag>
        <ImageWrapper>
          <LazyImage src={image.src} alt={image.alt ?? name} fill />
        </ImageWrapper>
        <Content>
          <WithLine margin="0 0 0.5rem">
            <Text as="time" size={14} themecolor="darkgray">
              {formatDate(date)}
            </Text>
            <Text as="h4" weight={600} margin="0" size={18}>
              {title}
            </Text>
          </WithLine>
          <Text
            margin="0.75rem"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
          <Icon src={arrowRight} />
        </Content>
      </InnerWrapper>
    </Wrapper>
  )
}

export default SingleLogBuildingCard
