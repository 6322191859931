import React, { useState, useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import HeaderFilters, {
  HeaderFilterOption,
} from 'components/layout/Headers/Secondary/Filters'
import LogBuildingSection from 'components/layout/Sections/LogBuilding'

import getUniqueArrayByObjectProperty from 'utils/getUniqueArrayByObjectProperty'

import type { LogBuildingPageQuery } from 'types/graphql'
import type { LogBuildingCard } from 'components/layout/LogBuilding/Card'

const LogBuildingPage: React.FC<PageProps<LogBuildingPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.page?.pageLogBuilding
  const PAGE_SEO = data?.page?.seo

  const LOGS_CATEGORIES = getUniqueArrayByObjectProperty(
    'slug',
    data.categories.nodes
  )

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const [activeLogs, setActiveLogs] = useState(['all'])

  const toggleOption = (value: string) => {
    if (value === 'all') {
      setActiveLogs(['all'])
      return
    }
    if (activeLogs.includes(value)) {
      const newLogs = activeLogs.filter((el) => el !== value)
      setActiveLogs(newLogs.length === 0 ? ['all'] : newLogs)
      return
    }
    setActiveLogs([...activeLogs.filter((el) => el !== 'all'), value])
  }

  const FILTERS: HeaderFilterOption[] = [
    { name: 'Wszystkie', slug: 'all' },
    ...LOGS_CATEGORIES,
  ].map(({ name, slug }) => ({
    type: 'filter',
    label: name!,
    active: activeLogs.includes(slug!),
    callback: () => toggleOption(slug!),
  }))

  const BUILDING_LOGS: LogBuildingCard[] = useMemo(
    () =>
      LOGS_CATEGORIES.filter(({ logsBuilding }) => logsBuilding?.nodes?.length)
        .map(({ name, slug, logsBuilding }) => {
          const lastPost = logsBuilding!.nodes![0]!
          return {
            image: {
              src: lastPost.logBuildingFields?.logBuildingS1Img?.localFile
                ?.childImageSharp?.gatsbyImageData!,
              alt: lastPost.logBuildingFields?.logBuildingS1Img?.altText!,
            },
            name: name!,
            slug: slug!,
            title: lastPost.logBuildingFields?.logBuildingS1Title!,
            excerpt: lastPost.logBuildingFields?.logBuildingExcerpt!,
            link: `/dziennik-budowy/${slug}/${lastPost.slug}`,
            date: new Date(lastPost.dateGmt),
          }
        })
        .sort((a, b) => b.date.getTime() - a.date.getTime()),
    [LOGS_CATEGORIES]
  )

  return (
    <Layout background="white50">
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Dziennik budowy' }]} />
      <Header
        title={PAGE.logBuildingPageS1Title!}
        image={{
          src: PAGE.logBuildingPageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.logBuildingPageS1Img?.altText!,
        }}
      />
      <main>
        <HeaderFilters options={FILTERS} />
        <LogBuildingSection
          logs={BUILDING_LOGS.filter(
            ({ slug }) =>
              activeLogs.includes(slug) || activeLogs.includes('all')
          )}
        />
      </main>
    </Layout>
  )
}

export default LogBuildingPage

export const query = graphql`
  query LogBuildingPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/dziennik-budowy/" }
    ) {
      seo {
        ...WpSEO
      }
      pageLogBuilding {
        logBuildingPageS1Title
        logBuildingPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    categories: allWpLogBuildingCategory(
      filter: {
        logsBuilding: {
          nodes: {
            elemMatch: {
              logBuildingFields: { logBuildingLang: { eq: $locale } }
            }
          }
        }
      }
    ) {
      nodes {
        name
        slug
        logsBuilding {
          nodes {
            slug
            dateGmt(locale: $locale)
            logBuildingFields {
              logBuildingS1Title
              logBuildingExcerpt
              logBuildingS1Img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 700
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
