const getUniqueArrayByObjectProperty = <T extends Object>(
  key: keyof T,
  array: T[]
): T[] => {
  const objUniq = array.reduce(
    // @ts-ignore
    (res, item) => ({ ...res, [item[key]]: item }),
    {}
  )
  // @ts-ignore
  return Object.keys(objUniq).map((item) => objUniq[item])
}

export default getUniqueArrayByObjectProperty
